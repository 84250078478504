import {browserTracingIntegration, thirdPartyErrorFilterIntegration} from "@sentry/vue";
import flipper from "@songfinch/shared/plugins/flipper/index";

export const sentryBaseConfig = {
    dsn: window.appSettings.sentry_dns,
    //release: window.appSettings.release_version, //???
    normalizeDepth: 10,
    debug: flipper.sentry_debug,
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    integrations: [
        browserTracingIntegration(),
        thirdPartyErrorFilterIntegration({
            filterKeys: ["songfinch-applications"],
            behaviour: "apply-tag-if-contains-third-party-frames",
        }),
    ],
    tracesSampleRate: window.appSettings.env === "production" ? 0.1 : 1.0, // ???
    tracePropagationTargets: ["localhost", /^https:\/\/.*\.songfinch\.com/, /^https:\/\/songfinch\.com/],
    ignoreErrors: [
        /^AbortError:/,
        /^Illegal invocation/, //Some TikTok pixel error
        /^Ignore:/,
        /^Blocked a frame with origin/,
        /^ResizeObserver loop/,
        /^Request aborted/,
        /^Unable to preload CSS/,
        /^Java exception was raised during method invocation/,
        /^Failed to load https\:\/\/www\.googletagmanager\.com\/gtag\/js/,
        /AutoFillPopupClose/, // caused by Chrome's autofill
        /Network Error/,
        /^Cannot read property 'setIsViewable|fireEvent|setScreenSize|setMaxSize|setDefaultPosition|setCurrentPosition|fireChangeEvent|audioVolumeChange|fireReadyEvent'/,
        /^Can't find variable: gmo/,
        /^Non-Error promise rejection captured/,
        /^The request is not allowed by the user agent/, // Caused by users no allowing permissions in browser
        /^Can't find variable: logMutedMessage/, // only in microsoft edge on mobile (pretty sure caused by the browser)
        /sentry-browser\.min\.js/ // excluding error everytime when zendesk can't load sentry-browser.min.js cuz of cache
    ],
    denyUrls: [
        /^https:\/\/add-a-card\.americangreetings\.com\/js\/sdk\.min\.js$/, // ignores AG SDK errors
        /^https?:\/\/(.+\.)?userway\.org/,
        /^chrome-extension:\/\//
    ]
};